import React from 'react';

import SEO from '../blocks/layout/seo';
import FormContainer from '../blocks/layout/formContainer';

const Billing = () => {
    return (
        <>
            <SEO title="One place for all your forms — FormWithSheet" />
            <FormContainer menuKey="4">
                <h2>Things to finish.</h2>
            </FormContainer>
        </>
    );
};

export default Billing;
